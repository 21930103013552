<template>
  <v-popover-wrapper :disabled="phones.length <= 1" popover-inner-class="phones-popover__tooltip-inner" is-click-stop>
    {{ setPhonesTitle(phones) }}
    <template #popover>
      <div class="phones-popover__section">
        <div class="phones-popover__section-header">Основной</div>
        <span class="phones-popover__phone">{{ formattedPhones[0] }}</span>
      </div>
      <div class="phones-popover__section">
        <div class="phones-popover__section-header">Дополнительный</div>
        <div class="phones-popover__list">
          <span v-for="(phone, index) in formattedPhones.slice(1)" :key="index" class="phones-popover__phone">
            {{ phone }}
          </span>
        </div>
      </div>
    </template>
  </v-popover-wrapper>
</template>

<script>
import { formatPhone } from '@/utils/formatters'
import { pluralizeNumeral } from '@/utils/pluralization'
import VPopoverWrapper from '@/components/common/VPopoverWrapper.vue'

export default {
  name: 'VPhonesPopover',
  components: { VPopoverWrapper },
  props: {
    phones: { type: Array, default: () => [] }
  },
  computed: {
    formattedPhones() {
      return this.phones.map(phone => formatPhone(phone))
    }
  },
  methods: {
    setPhonesTitle(phones) {
      const count = phones.length
      const text = pluralizeNumeral(count, 'номер', 'номера', 'номеров')
      if (count > 1) return `${count} ${text}`
      return formatPhone(phones[0])
    }
  }
}
</script>
